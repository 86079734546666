import Banner from "../../blocks/Banner/Banner"
import Header from "../../blocks/Header/Header"
import Achievements from "../../blocks/Achievements/Achievements"
import About from "../../blocks/About/About"
import styles from "./Home.module.scss"

export default function Home() {
	return (
		<>
			<div className={styles.headerWrapper}>
				<Header />
				<Banner />
			</div>
			<div className={styles.main}>
				<About />
				<Achievements />
			</div>
		</>
	)
}
