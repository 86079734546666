import Container from "../../UI/Container/Container"
import styles from "./About.module.scss"

export default function About() {
	return (
		<section id='about'>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<h2>О КОМПАНИИ</h2>
					<div className={styles.content}>
						<div className={styles.textWrapper}>
							<h3>30 СЕНТЯБРЯ 1999</h3>
							<p>
								Компания JAC MOTORS образована 30/09/1999 на базе автозавода «Цзянхуай» провинция Хэфей (Hefei Jianghuai Automobile Factory).
								Завод основан в 1964 году и на сегодняшний день JAC Motors является современным, высокотехнологичным предприятием. В 2001-ом
								году компания JAC разместила свои акции на Шанхайской фондовой бирже (фондовый индекс: 600418).
							</p>
							<p>
								Компания JAC является комплексным предприятием, выпускающим под независимым брендом полную гамму автомобилей – легкие,
								среднетоннажные и тяжелые грузовики, минивэны, легковые автомобили, электромобили, автобусы, специальные шасси, погрузчики и
								другие виды техники, а также коробки передач для автомобилей, двигатели, автозапчасти, автоагрегаты.
							</p>
							<p>Компания JAC — поставщик техники для Вооруженных Сил КНР</p>
						</div>
						<img className={styles.contentImage} src={`/images/about.jpg`} alt='офис компании' width={552} height={368} loading='lazy' />
					</div>
				</div>
			</Container>
			<picture>
				<source className={styles.image} srcSet={"/images/manifest.jpg"} width={1920} height={728} media='(min-width: 768px)' />
				<img className={styles.image} src={`/images/manifest-m.jpg`} alt='модели JAC' width={600} height={230} loading='lazy' />
			</picture>
		</section>
	)
}
