import {useKeenSlider} from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

import {ARTICLES} from "../../../achievementsConfig"
import Article from "../Article/Article"
import styles from "./Slider.module.scss"

export default function Slider() {
	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		slides: {
			perView: 1.2,
			spacing: 20,
		},
	})

	return (
		<div ref={sliderRef} className={`keen-slider ${styles.slider}`}>
			{ARTICLES.map((item, idx) => (
				<div className='keen-slider__slide' key={idx}>
					<Article item={item} />
				</div>
			))}
		</div>
	)
}
