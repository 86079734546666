export const PHONE = {
	phone: "+7 (812) 413-97-29",
	href: "tel:+78124139729",
}

export const MENU = [
	{
		name: "О компании",
		section: "about",
	},
	{
		name: "Достижения",
		section: "achievements",
	},
]
