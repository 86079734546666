export const ARTICLES = [
	{
		title: "«БРЕНД, РЕКОМЕНДОВАННЫЙ ДЛЯ ЭКСПОРТА»",
		text: "Награда «Бренд, рекомендованный для экспорта» является паспортом качества для китайских экспортных товаров. Данный сертификат подтверждает не только наличие системы управления качеством предприятия в соответствии с международными стандартами, но разрешение для предприятия на экспорт продукции.",
		image: "0",
	},
	{
		title: "высокий объем экспорта",
		text: `Объем экспорта автомобилей составляет более 70 000 ед. с ежегодным приростом в среднем на 50%. На протяжении 18-ти лет подряд JAC занимает первое место по экспорту специальных шасси для пассажирских автобусов, на протяжении 13-ти лет – первое место по экспорту легких коммерческих автомобилей. Основными рынками сбыта являются страны Южной Америки, Ближнего Востока, Северной Африки, Юго-Восточной Азии и Австралия. Вся поставляемая техника JAC соответствует строгим требованиям экологических стандартов этих стран. На сегодняшний день компанией JAC Motors организованы сборочные предприятия с различной степенью локализации производства более чем в 15 странах. На внутреннем рынке компания занимает одно из первых мест по реализации тяжелых грузовых автомобилей с объемом производства более 60 тысяч единиц в год.`,
		image: "",
	},
	{
		title: "«НАЦИОНАЛЬНЫЙ ЗНАК КАЧЕСТВА»",
		text: "Китайская премия качества является высшей государственной наградой за качество продукции. Компания JAC Motors является первой национальной компанией автопроизводителем, которая получила эту награду.",
		image: "",
	},
]
