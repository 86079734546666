import {ARTICLES} from "../../../achievementsConfig"
import {useWindowSize} from "../../../hooks/useWindowSize"
import Container from "../../UI/Container/Container"
import Article from "../Article/Article"
import Slider from "../Slider/Slider"
import styles from "./Achievements.module.scss"

export default function Achievements() {
	const windowWidth = useWindowSize().width

	return (
		<section id='achievements'>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<div className={styles.titleWrapper}>
						<h2 className={styles.title}>ДОСТИЖЕНИЯ</h2>
						<p>
							В июне 2005 года компания, первой в отрасли, открыла зарубежный дизайн-центр в Италии, а в октябре 2006 года учредила второй
							дизайн-центр в Токио. В компании имеется собственный центр технологических разработок и исследований, испытательный полигон и
							инженерно-лабораторный комплекс.
						</p>
					</div>
					{windowWidth < 1024 ? (
						<Slider />
					) : (
						<div className={styles.list}>
							{ARTICLES.map((item, idx) => (
								<div key={idx}>
									<Article item={item} />
								</div>
							))}
						</div>
					)}
				</div>
				<div className={styles.bottomContent}>
					<div className={styles.textWrapper}>
						<p>
							Многофункциональные коммерческие автомобили JAC на протяжении нескольких лет подряд получают почетную награду «Лучший MPV года в
							Китае» и стабильно завоевывают первенство по объему реализации. Легковой автомобиль класса B, дизайн которого разрабатывался
							совместно с дизайн-ателье “PININFARINA”, назван «самым красивым автомобилем за последние десять лет», прошел C-NCAP краш-тест c
							отличием, и получил почетную награду «Лучшая Модель 2011 года». Самостоятельно разработанные двигатели для линейки легковых
							автомобилей моделей 1.3LVVT, 1.5LVVT и 2,0LTCI трижды (2009г., 2010г. и 2011г.) получали звание «Десять Лучших Двигателей Китая».
						</p>
						<p>Модель S3 является флагманом китайского рынка SUV и входит в тройку лучших городских кроссоверов Китая.</p>
						<p>
							Одним из новых направлений деятельности Компании является производство автомобилей на альтернативных видах топлива. На сегодняшний
							день на дорогах КНР уже эксплуатируются более 10 тысяч единиц электромобилей шестого поколения, запас хода которого составляет около
							250 километров. В 2017 году запущено производство электромобилей седьмого поколения с запасом хода 350 км.
						</p>
					</div>
					<picture>
						<source srcSet={"/images/main.jpg"} width={1136} height={520} media='(min-width: 768px)' />
						<img className={styles.image} src={`/images/main-m.jpg`} alt='' width={560} height={240} loading='lazy' />
					</picture>
				</div>
			</Container>
		</section>
	)
}
