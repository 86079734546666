import Container from "../../UI/Container/Container"
import Logo from "../../UI/Logo/Logo"
import Icon from "../../UI/Icon/Icon"
import {PHONE} from "../../../utils/constants"
import styles from "./Header.module.scss"
import Menu from "../../UI/Menu/Menu"
import {useWindowSize} from "../../../hooks/useWindowSize"

export default function Header({isBlack = false}) {
	const windowWidth = useWindowSize().width

	return (
		<header className={styles.container}>
			<Container noPaddingBottom noPaddingTop>
				<div className={styles.wrapper}>
					<div className={styles.upperSection}>
						<Logo isBlack={isBlack} />
						<div className={styles.rightSection}>
							{windowWidth > 980 && !isBlack && <Menu />}
							<a href={PHONE.href} className={styles.link} style={isBlack ? {color: "black"} : {}}>
								<div className={`${styles.icon} ${isBlack ? styles.iconBlack : ""}`}>
									<Icon.Phone />
								</div>
								<span className={styles.phone}>{PHONE.phone}</span>
							</a>
						</div>
					</div>
					{windowWidth < 980 && !isBlack && <Menu />}
				</div>
			</Container>
		</header>
	)
}
